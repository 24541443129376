import { useState } from "react";
import axios from "axios";
import { FcCheckmark } from "react-icons/fc";
import subscribeBody from "./SubscribeRequest";


const UnsubscribeForm = () => {

    const [email, setEmail] = useState("");
    const [userSubmit, setUserSubmit] = useState(false);
    

    /**
     * Resets any error messages that were created from the user entering invalid input.
     */
      function resetErrors(){
        document.getElementById("emailInput").style.border = "1px #D0CCC7 solid";
    }

    /**
     * Checks whether the form the user has filled out is of valid form.
     * @returns true if the form is valid, false otherwise
     */
     function validateForm() {
        resetErrors();
        var success = true;
        if (email === ""){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        } else if (validateEmail(email)){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        }
        return success;
    }

    /**
     * Used to check the validity of the email.
     * @param {String} email Passed in from the email input.
     * @returns True if the email is of vliad form, false otherwise.
     */
    function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    /**
     * Sends a GET req to trigger/send endpoint using the users email, and the name for the trigger in the sendBody.
     * @param {event} event Generated on form submit
     */
    const handleUnsubscribeRequest = async(event) => {
        event.preventDefault();
        
        if(validateForm()){
            const sendBody = subscribeBody;
            sendBody.email = email;
            sendBody.subscriptionPermissions[0].permission = "Unsubscribed";
            sendBody.subscriptionPermissions[0].permissionCaptureDateTime = new Date().toISOString();
            const axiosConfig = {
                headers: {
                    appid: "Kr!H5Sr$$9Z^3WNc%9HHKPSbw#"
                },
            };
            try {
                axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/subscribe`,
                    sendBody,
                    axiosConfig
                ).then(response => {
                    //User was not subscribed to the service
                    setUserSubmit(true);
                    console.log("User subscribed" + response.content);
                }).catch((error) => {
                    //User is already subscribed to the service
                    console.log(error);
                    console.log(error.message);
                    console.log(error.response);
                });
                
            } catch (error) { 
                console.error(error);
            }
        }
    }

    return (
        <div className="main-container">
            <a href="https://www.alaskacommercial.com/" target="_blank" rel="noreferrer" className="header-container">
                <div className="header-body">
                    <img alt="Logo" src={require("./images/logo_alaska.jpg")}/>
                </div>
            </a>
            <div className="body-container">
                <div className="body-header">
                    {userSubmit ?
                        <>You have successfully unsubscribed.</>
                        :
                        <>Please enter the email you'd like to unsubscribe from our service.</>
                    }
                </div>
                <div className="field-container-email">
                    {userSubmit ? 
                    <FcCheckmark size={150} color="#7DC243"/>
                    :
                    <div className="form-section"  id="mainContent">
                        <form id="contact" name="contact" onSubmit={handleUnsubscribeRequest} className="form-section">
                            <div className="form-field" id="email">
                                <input type="text" value={ email } placeholder="Email" name="email" id="emailInput" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="button-field">
                                <label id="button-label" htmlFor="submit" className="form-field-label"></label>
                                <button className="button-submit">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                    }    
                </div>  
            </div>  
        </div>
)
}

export default UnsubscribeForm;

