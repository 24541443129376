import { useState } from "react";
import axios from "axios";
import triggerBody from "./TriggerRequest";
import { IoShieldCheckmarkOutline } from "react-icons/io5";


const SendTriggerForm = () => {

    const [email, setEmail] = useState("");
    const [userSubmit, setUserSubmit] = useState(false);
    
    /**
         * Resets any error messages that were created from the user entering invalid input.
         */
     function resetErrors(){
        document.getElementById("emailInput").style.border = "1px #D0CCC7 solid";
    }

    /**
     * Checks whether the form the user has filled out is of valid form.
     * @returns true if the form is valid, false otherwise
     */
    function validateForm() {
        resetErrors();
        var success = true;
        if (email === ""){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        } else if (validateEmail(email)){
            document.getElementById("emailInput").style.border = "1px red solid";
            success = false;
        }
        return success;
    }

    /**
     * Used to check the validity of the email.
     * @param {String} email Passed in from the email input.
     * @returns True if the email is of vliad form, false otherwise.
     */
    function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    /**
     * Sends a GET req to trigger/send endpoint using the users email, and the name for the trigger in the sendBody.
     * @param {event} event Generated on form submit
     */
    const handleTriggerRequest = async(event) => {
        event.preventDefault();
        const axiosConfig = { 
            headers : {
                appID: 'Kr!H5Sr$$9Z^3WNc%9HHKPSbw#'
            }
        }
        if(validateForm()){
            const sendBody = triggerBody;
            sendBody.send.email = email;
            sendBody.send.triggerName = "Verify User";
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/send`, sendBody.send, axiosConfig)
            .then(res => {
                setUserSubmit(true);
            });
        } 
    }

    return (
        <div className="main-container">
            <a href="https://www.alaskacommercial.com/" target="_blank" rel="noreferrer" className="header-container">
                <div className="header-body">
                    <img alt="Logo" src={require("./images/logo_alaska.jpg")}/>
                </div>
            </a>
            <div className="body-container">
                <div className="body-header">
                    {userSubmit ?
                        <>Thank you, you should receive an email shortly with a secure link to edit/create your information.</>
                        :
                        <>Please enter your email to receive a secure link which can be used to setup or update your information.</>
                    }
                </div>
                <div className="field-container-email">
                    {userSubmit ? 
                    <IoShieldCheckmarkOutline size={200} color="#7DC243"/>
                    :
                    <div className="form-section"  id="mainContent">
                        <form id="contact" name="contact" onSubmit={handleTriggerRequest} className="form-section">
                            <div className="form-field" id="email">
                                <input type="text" value={ email } placeholder="Email" name="email" id="emailInput" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="button-field">
                                <label id="button-label" htmlFor="submit" className="form-field-label"></label>
                                <button className="button-submit">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                    }    
                </div>  
            </div>  
        </div>
)
}

export default SendTriggerForm;

