const triggerBody = {
    //testing
    send: {
        email: "",
        triggerName: "Verify User",
        company: "Alaska Commercial"
    },
    verify: {
        id: ""
    }
};

export default triggerBody;