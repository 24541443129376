const subscribeBody = {
  email: "",
  id: "",
  fields: [
    {
      name: "Email",
      value: "",
    },
    {
      name: "First Name",
      value: "",
    },
    {
      name: "Last Name",
      value: "",
    },
    {
      name: "Store Name",
      value: "",
    },
  ],
  subscriptionPermissions: [
    {
      name: "Promotional Email Communications",
      permission: "Unassigned",       
    },
  ],
};

export default subscribeBody;
